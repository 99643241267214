.workspace img {
  width: 36px;
  height: 36px;
}
.dropdown-menu-center {
  left: 50% !important;
  right: auto !important;
  transform: translate(-41.5%, 53px) !important;
  user-select: none;
}
.dropdown-menu .dropdown-heading {
  padding: 0.25rem 1rem;
  pointer-events: none;
  font-size: 0.95rem;
}
.custom-nav-link-text {
  width: 100%;
  color: var(--color-primary-admin);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.add-workspace {
    justify-content: start !important;
    padding-block: 0.25rem;
  }
  i {
    margin-right: 0.35rem;
    font-size: 0.8rem;
  }
}
.add-workspace-link:hover {
  transition: all 0.15s;
  background-color: rgba(0, 0, 0, 0.01);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.02);
}
.custom-nav-link-text[data-toggle='collapse']:after {
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  content: '\f105';
  color: inherit;
  transition: all 0.15s ease;
}
.custom-nav-link-text[data-toggle='collapse'][aria-expanded='true']:after {
  transform: rotate(90deg);
}
.nav-heading {
  background-color: #fff;
  border-radius: 5px;
  &.nav-heading-text {
    font-size: 1rem !important;
  }
}
.pointer-event-none {
  pointer-events: none;
  user-select: none;
}
.custom-nav-link {
  padding: 0.25rem 1.5rem;
  font-size: 0.85rem;
  color: var(--color-primary-admin);
  &[aria-selected='true'] {
    background-color: #e5feff;
  }
  &[aria-checked='true'] {
    background-color: #f3f4f5;
  }
}

.workspace-modal {
  &-header {
    padding-inline: 1.5rem;
  }
  &-body {
    .invalid-input {
      box-shadow: 0px 0px 0px 1px #c2241c;
    }
  }
  &-footer {
    padding-top: 0.1rem;
  }
}
.custom-dropdown-menu {
  top: -14px !important;
}
.dropdown-item-nav {
  padding: 0;
  &:focus,
  &:hover {
    background-color: #fff;
  }
  .custom-nav-link-text {
    padding: 0.55rem 0.75rem;
    color: #32325d;
    display: flex;
    justify-content: space-between;
    width: 9rem;
  }
}
