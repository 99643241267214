html,
body {
  height: 100%;
  @media only screen and (max-width: 1363.98px) {
    font-size: 14px;
  }
}
body {
  overflow-x: hidden;
}

.Toastify__toast-body {
  div {
    color: #fff;
  }
}
