.calendar-view {
  .calendar-month {
    font-size: 1.25rem;
    text-align: center;
  }
  .calendar {
    display: grid;
    place-items: center;
    .DayPicker-Caption {
      text-align: center;
    }
    .DayPicker,
    .DayPicker-wrapper {
      width: 100%;
    }
    .DayPicker-Day {
      text-align: left;
      border-radius: 0;
      font-weight: 300;

      position: relative;

      &--today:not(.DayPicker-Day--outside) {
        color: unset;
      }
    }
    .weekday-content {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      font-size: 0.875rem;
      margin-bottom: 0.25rem;
      padding-inline: 0.5rem;
      width: 100%;
      img {
        margin-left: 0.25rem;
      }
    }
    .DayPicker {
      &-Month {
        border-collapse: separate;
      }
      &-Day {
        border: 1px solid #f6f6f6;
        border-style: none solid solid none;
      }
      &-Week {
        &:first-child {
          .DayPicker-Day {
            border-top-style: solid;
          }
          .DayPicker-Day:first-child {
            border-top-left-radius: 7px;
          }
          .DayPicker-Day:last-child {
            border-top-right-radius: 7px;
          }
        }
        &:last-child {
          .DayPicker-Day:first-child {
            border-bottom-left-radius: 7px;
          }
          .DayPicker-Day:last-child {
            border-bottom-right-radius: 7px;
          }
        }
        .DayPicker-Day:first-child {
          border-left-style: solid;
        }
      }
    }
  }
}

.multiple-months-view {
  .DayPicker-Caption {
    font-size: 1rem;
  }
  .DayPicker-Months {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .DayPicker-Month {
    width: 30%;
  }
  .DayPicker-Day {
    font-size: 0.75rem;
  }
}
.single-months-view {
  .DayPicker-wrapper {
    padding-bottom: 2rem;
  }
  .DayPicker-Caption {
    font-size: 1.5rem;
  }
  .DayPicker-Month {
    margin: 0rem 2rem;
  }
  .DayPicker-Months,
  .DayPicker-Month {
    width: 100%;
  }
  .DayPicker-Day {
    padding-bottom: 2.9rem;
    font-size: 1rem;
  }
}
.admin-calendar {
  .calendar {
    .DayPicker-Day {
      &--selected:not(.DayPicker-Day--outside) {
        background-color: #73cdd2 !important;
        color: #fff;
        &:hover {
          background-color: #55c8ce;
        }
      }
      &--highlighted:not(.DayPicker-Day--outside) {
        background-color: #e6f4f5;
        font-weight: 400;
      }

      &--loggedToday:not(.DayPicker-Day--outside) {
        background-color: #34bac1 !important;
        color: #fff;
      }
    }
  }
}

.user-calendar {
  .calendar {
    .DayPicker-Day {
      &--selected:not(.DayPicker-Day--outside) {
        background-color: #73cdd2 !important;
        color: #fff;
        &:hover {
          background-color: #73cdd2;
        }
      }
      &--highlighted:not(.DayPicker-Day--outside) {
        background-color: #e6f4f5;
        font-weight: 400;
      }

      &--loggedToday:not(.DayPicker-Day--outside) {
        background-color: #34bac1 !important;
        color: #fff;
      }
    }
  }
}

.calendar-view-modal-body {
  max-height: 70vh;
  overflow: auto;
}
