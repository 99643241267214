.display-flex {
  display: flex;
  align-items: center;
}
.table-container-header {
  @extend .display-flex;
  justify-content: space-between;
  width: 100%;
  .custom-action-card {
    @extend .display-flex;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    transition: box-shadow 0.15s ease;
    padding: 0.6rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    margin-left: 1rem;
    cursor: pointer;
    user-select: none;
    color: #525f7f;
    i,
    img {
      margin-right: 0.6rem;
    }
    div {
      display: flex;
    }
  }
}
.date-input-container {
  display: flex;
  align-items: center;
  margin-right: 3rem;
}
.date-input-container:first-child {
  .form-control,
  .date-text {
    margin-bottom: 0.5rem;
  }
}
.visibility-hidden {
  visibility: hidden;
}

.table-container {
  &-select-group {
    display: flex;
    padding-top: 1.25rem;
    padding-left: 1rem;
    .form-group {
      margin-bottom: 0px !important;
    }
  }
  &-heading-select {
    flex-basis: 67%;
  }
  &-dates {
    flex-basis: 28%;
    font-size: 0.875rem;
  }
  &-add-view {
    flex-basis: 15%;
    .view-selector {
      margin-top: 1.25rem;
      margin-right: 1rem;
    }
  }
}
.add-log-button {
  width: 100%;
  &-container {
    margin-right: 1rem;
  }
}

.delete-icon {
  color: #e55c5c;
}
.date-text {
  width: 3rem;
}

.disabled.custom-action-card {
  cursor: not-allowed;
  color: #525f7f8c;
  a {
    cursor: not-allowed;
  }
}
.custom-action-card {
  a {
    color: #525f7f;
  }
}
