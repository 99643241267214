.modal-unfilled {
  cursor: pointer;
  color: $base-color;
  border: 1px solid $base-color;
  border-radius: 7px;
  font-size: 0.85rem;
  padding: 0.25rem 1.75rem;
  transition: all 0.15s;
  background-color: #fff;
}

.modal-filled {
  @extend .modal-unfilled;
  background-color: $base-color;
  color: #fff;
  display: flex;
}
.modal {
  &-header {
    padding-top: 1.2rem;
    border-bottom: none;
    .modal-title {
      font-size: 1rem;
    }
  }
  &-body {
    padding-block: 0.25rem;
    input {
      background: #f4f5fa;
      padding: 0.45rem 0.75rem;
      width: 100%;
      border: none;
      border-radius: 5px;
      font-size: 0.9rem;
      &:focus {
        outline: none;
      }
    }
  }
  &-footer {
    border-top: none;
    padding-bottom: 1.2rem;
  }
}
