.slider-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1.2rem;
  padding-left: 5.2rem;
}
.slider-component-wrapper {
  width: 100%;
}
.slider-component {
  display: flex;
  position: relative;
}
.slider-button-wrapper {
  position: relative;
  top: 1rem;
}
.time-log-modal {
  max-width: 80vw !important;
}
@media only screen and (min-width: 1363.98px) {
  .time-log-modal,
  .time-log-modal .modal-content {
    max-width: 55vw !important;
  }
}
@media only screen and (max-width: 1363.98px) and (min-width: 768px) {
  .time-log-modal,
  .time-log-modal .modal-content {
    max-width: 75vw !important;
  }
}
@media only screen and (min-width: 451px) and (max-width: 600px) {
  .time-log-modal,
  .time-log-modal .modal-content {
    max-width: 90vw !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 450px) {
  .time-log-modal,
  .time-log-modal .modal-content {
    max-width: 100vw !important;
  }
}
.filter-form-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}
.custom-date-to,
.filter-icon-wrapper {
  padding: 0 1rem;
}
.edit-slider-form {
  padding-right: 5.2rem;
}
.not-found-message {
  padding: 3rem;
  text-align: center;
  font-style: italic;
}
