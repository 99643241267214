.total-hours-container {
  padding-block: 1rem 2rem;
  font-size: 0.9rem;
  width: 100%;
  &.display-flex {
    justify-content: center;
  }
  .total-hours {
    line-height: 30px;
    width: 17rem;
    text-align: center;
    border-radius: 10px;
    background-color: #f8f9fe;
    padding-inline: 1rem;
    &-text {
      font-weight: 600;
    }
  }
}
.project-log-table-row tr,
.project-log-table-row td {
  width: 100px;
  max-width: 100px;
  height: 50px;
  max-height: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.td-details {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.admin-logs-table th:first-child {
  width: 1%;
}

.admin-logs-table th:nth-last-child(7) {
  width: 10%;
}
.admin-logs-table th:nth-last-child(6) {
  width: 12%;
}
.admin-logs-table th:nth-last-child(5) {
  width: 12%;
}
.admin-logs-table th:nth-last-child(4) {
  width: 20%;
}
.admin-logs-table th:nth-last-child(3) {
  width: 15%;
}
.admin-logs-table th:nth-last-child(2) {
  width: 20%;
}

.admin-logs-table th:last-child {
  width: 15%;
}

.employee-logs-table {
  border-bottom: 1px solid #e9ecef;
}
.employee-logs-table th:first-child {
  width: 1%;
}
.employee-logs-table th:nth-child(2) {
  width: 12%;
}
.employee-logs-table th:nth-child(3) {
  width: 12%;
}
.employee-logs-table th:nth-child(4) {
  width: 17%;
}
.employee-logs-table th:nth-child(5) {
  width: 12%;
}
.employee-logs-table th:nth-child(6) {
  width: 15%;
}
.employee-logs-table th:nth-child(8) {
  width: 5%;
}
.employee-logs-table td:nth-child(8) {
  text-align: center;
}
.employee-logs-table th:nth-child(7) {
  width: 15%;
}

@media only screen and (max-width: 1363.98px) {
  .admin-logs-table .project-log-table-row td:not(:last-child) {
    padding-right: 0px;
  }
  .employee-logs-table .project-log-table-row td:not(:last-child) {
    padding-right: 0px;
  }
  .navbar-vertical.navbar-expand-md {
    max-width: 225px;
  }
  .navbar-vertical.navbar-expand-md.fixed-left + .main-content {
    margin-left: 225px;
  }
}

@media only screen and (max-width: 767.98px) {
  .navbar-vertical.navbar-expand-md {
    max-width: 100%;
  }
  .navbar-vertical.navbar-expand-md.fixed-left + .main-content {
    margin-left: 0px;
  }
}

.flex-with-centered-content,
.logs-card-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logs-card-body-container {
  display: flex;
  padding-top: 0rem;
}
.logs-card-body .mb-3 {
  margin-bottom: 0 !important;
}
.logs-spinner {
  display: grid;
  place-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.disabled-icon {
  pointer-events: none;
  color: #aaa !important;
}
.in-progress {
  font-style: italic;
}
.load-more-btn {
  border-radius: 1rem;
  color: #2ececc;
  padding-inline: 1rem;
  padding-block: 0.3rem;
  border: 1px solid #2ececc;
  margin-left: 1rem;
  cursor: pointer;
}
.load-more-btn:hover {
  color: #fff;
  background-color: #2ececc;
}
.user-load-btn {
  color: var(--color-primary-user) !important;
  border: 1px solid var(--color-primary-user) !important;
}

.user-load-btn:hover {
  background-color: var(--color-primary-user) !important;
  color: #fff !important;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}
.load-more-container {
  width: 60%;
}
.markdown-toogle {
  width: 33px;
  height: 1.2rem;
}
.markdown-toogle-slider:before {
  height: 13px;
  width: 13px;
  top: 1.5px;
}
@media only screen and (max-width: 1363.98px) {
  .markdown-toogle-slider:before {
    height: 11px;
    width: 11px;
    top: 1.5px;
  }
}
.left:before {
  right: 0.1rem;
}

.right:before {
  left: -0.6rem;
}

.disabled-icon {
  color: black;
  pointer-events: none;
}
