@font-face {
  font-family: futura;
  src: url(assets/fonts/futura.ttf);
}
@font-face {
  font-family: futura-light;
  src: url(assets/fonts/futura-light.ttf);
}
@font-face {
  font-family: addington;
  src: url(assets/fonts/AddingtonCF.ttf);
}
@font-face {
  font-family: addington-light;
  src: url(assets/fonts/AddingtonCF-Light.ttf);
}
@font-face {
  font-family: addington-thin;
  src: url(assets/fonts/AddingtonCF-Thin.ttf);
}

@font-face {
  font-family: sanFranciso;
  src: url(assets/fonts/San-Francisco-Pro/SF-Pro-Text-Regular.otf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: sanFranciso;
  src: url(assets/fonts/San-Francisco-Pro/SF-Pro-Text-RegularItalic.otf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: sanFranciso;
  font-weight: 500;
  font-style: normal;
  src: url(assets/fonts/San-Francisco-Pro/SF-Pro-Text-Medium.otf);
}
@font-face {
  font-family: sanFranciso;
  font-weight: 300;
  font-style: normal;
  src: url(assets/fonts/San-Francisco-Pro/SF-Pro-Text-Light.otf);
}
@font-face {
  font-family: sanFranciso;
  font-weight: 600;
  font-style: normal;
  src: url(assets/fonts/San-Francisco-Pro/SF-Pro-Text-Bold.otf);
}
@font-face {
  font-family: sanFranciso;
  font-weight: 900;
  font-style: normal;
  src: url(assets/fonts/San-Francisco-Pro/SF-Pro-Text-Heavy.otf);
}
