.p-get_started_confirmation_code {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.p-get_started_confirmation_code__input_container {
  width: 100%;
  max-width: 500px;
}
.p-get_started_confirmation_code__input_fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
.offscreen {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  user-select: none;
}
.align_items_center {
  align-items: center;
}
.display_flex {
  display: flex;
}
.margin_bottom_50 {
  margin-bottom: 8px;
}
.confirmation_code_span_cell {
  font-weight: 400;
  font-size: 15px;
  vertical-align: middle;
}
.margin_50 {
  margin: 8px;
}
.split_input_item:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.split_input_item:nth-child(2) {
  border-style: solid none;
}
.split_input_item:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.split_input_item {
  border: 1px solid rgba(29, 28, 29, 0.5);
}
.confirmation_code_checker {
  margin-top: 15px;
}
.p-get_started_confirmation_code .c-alert--level_default {
  border-color: transparent;
  background-color: transparent;
}
.confirmation_code_checker .confirmation_code_state_message {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.38463;
  text-align: left;
  color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
}
.invisible {
  visibility: hidden;
}
.c-alert--align_center {
  justify-content: center;
}
.c-alert--boxed,
.c-alert--custom,
.c-alert--inline_boxed {
  color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
  font-weight: 400;
  font-size: 15px;
  line-height: 1.46666667;
  padding: 12px 16px;
}
.c-alert--level_default {
  border-radius: 4px;
  background-color: rgba(var(--sk_foreground_max_solid, 97, 96, 97), 0.1);
  --saf-0: rgba(var(--sk_foreground_max_solid, 97, 96, 97), 0.4);
  border: 1px solid var(--saf-0);
}
.c-alert {
  display: flex;
}

ol,
p,
ul {
  margin-bottom: 16px;
}
.split_input_item input {
  color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
  width: 100%;
  font-size: 38px;
  line-height: 52px;
  text-align: center;
  border: none;
  background: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
}
.invalid input {
  box-shadow: 0px 0px 5px 0px red;
}
.confirmation-page {
  &-content {
    max-width: 100rem;
  }
  .code-img {
    margin-bottom: 2.8rem;
  }
  &__heading {
    font-size: 1.5rem;
    margin-bottom: 1.2rem;
  }
  &__sub_heading {
    font-size: 0.9rem;
    margin-bottom: 2rem;
    div:first-child {
      margin-bottom: 0.25rem;
    }
    @media only screen and (max-width: 1200px) {
      width: 70%;
    }
    @media only screen and (max-width: 800.98px) {
      width: 90%;
    }
    @media only screen and (max-width: 630.98px) {
      width: auto;
    }
  }
}

.text-transformation {
  text-transform: uppercase;
}
