.card > hr {
  margin: unset;
}
.activity-list {
  background-color: #fff;

  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.activity-date-container {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}
.date-admin-header,
.date-user-header {
  padding: 0.918rem 1.5rem;
}

.activity-spinner-padding {
  padding-top: 1.175rem;
  padding-bottom: 1.5rem;
}
