.admin-action-bar {
  background-color: var(--color-primary-admin);
}
.admin-action-bar {
  padding: 0.326rem 0;
}
.admin-action-bar.archive {
  padding: 0px;
}

.user-action-bar {
  background-color: var(--color-primary-user);
}
.actions-bar {
  text-align: right;
  background-color: var(--color-primary-admin);
  &.disabled {
    color: var(--gray);
    i {
      cursor: not-allowed;
    }
  }
  &.user {
    background-color: var(--color-primary-user);
  }
  color: #fff;
  .avatar {
    background-color: transparent;
    width: 1.5rem;
  }

  .fa-trash-alt {
    font-size: 1.1em;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0rem 1.5rem;
  }
  img,
  i {
    cursor: pointer;
  }
}
