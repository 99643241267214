.activity-card {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 0.2px solid #e5e5e5;
  }
  padding: 1rem 1.5rem;
  .activity-type {
    margin-right: 1rem;

    i {
      width: 21px;
      text-align: center;
      color: var(--color-primary-user);
    }
  }
  p {
    margin: unset;
    font-size: 0.82rem;
    letter-spacing: 0.2px;
    span {
      font-weight: 400;
    }
    a {
      color: var(--color-primary-user);
      font-weight: 400;
    }
  }
}
.activity-operation-icon {
  width: 21px;
}

.activity-card.admin {
  .activity-type {
    i {
      color: var(--color-primary-admin);
    }
  }
  p {
    a {
      color: var(--color-primary-admin);
    }
  }
}

.custom-nav-user-link {
  font-size: 0.83rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  padding: 0.45rem 1rem;
}
.custom-nav-user-link.active {
  background-color: #f6f9fca1;
}
.custom-nav-user-link:hover {
  color: #525f7f;
}
.custom-nav-tabs {
  padding-left: 0.5rem;
}
