.project-container {
  padding: 1rem 1.5rem;
}
.project-list {
  display: grid;
  grid-template-columns: 33.33333333333% 33.33333333333% 33.33333333333%;

  a {
    margin: 0.75rem 0;
  }
}

@media screen and (max-width: 992px) {
  .project-list {
    grid-template-columns: 50% 50%;
  }
}

.data-loading-spinner {
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
}
