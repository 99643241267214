.Toastify__toast {
  border-radius: 4px;
}
.Toastify__toast-body {
  font-size: 1rem;
  letter-spacing: 1px;
  padding: 0.5rem 0.5rem;
}
.Toastify__toast--success {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
}
.Toastify__toast--error {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%) !important;
}
