.landing-page-container {
  background-color: $bg-color-1;
}
.workspace-field {
  position: absolute;
  top: 45%;
  left: 50%;
}

.input-field {
  color: #303f40;
  font-size: 20px;
  border: none;
  border-bottom: 1px solid #303f40;

  width: 20rem;
  margin-left: 0.25rem;
  padding-left: 0.5rem;
  background-color: transparent;
}
.input-field:focus {
  outline: none;
}

.available-alias {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: -50%;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  padding: 1.5rem 1.5rem 1.25rem;
  border-radius: 5px;
}
.available-alias-wrapper {
  display: flex;
  padding-top: 0.5rem;
}
.main-container {
  width: 100vw;
  background-color: $bg-color-2;
}

.arrow-image {
  padding-left: 1rem;
  cursor: pointer;
}
.error-message {
  font-size: 0.75rem;
  margin: 0;
  padding-left: 0.25rem;
}

.track-activities-container {
  padding-block: 5rem;
  position: relative;
  .seperation-line {
    opacity: 0.75;
    width: 25%;
    margin-inline: auto;
    margin-block: 0rem 1.5rem;
    height: 3px;
    color: #303f40;
    border-radius: 5rem;
  }
}
.track-activities {
  text-align: center;
  color: #ffffff;
  position: relative;

  .line-1 {
    position: absolute;
    right: 0;
    bottom: -4rem;
  }
  &-heading {
    font-weight: 500;
    color: #303f40;
  }
  &-text {
    font-weight: 300;
  }
  &-image {
    position: relative;
    margin-inline: 1.5rem;
    .admin-ss {
      padding: 3.5rem;
      img {
        width: 100%;
        position: relative;
        border-radius: 9px;
      }
    }
    .line-2 {
      position: absolute;
      bottom: 5rem;
      left: -1.5rem;
    }
  }
  &-text {
    font-size: 1.35rem;
    width: 42%;
    margin-inline: auto;
    color: #303f40;
  }
  &-button {
    line-height: 32px;
    color: #ffffff;
    background-color: #303f40;
    border-color: #303f40;
    padding: 0.2rem 2.5rem;
    border-radius: 100px;
    &:active,
    &:hover,
    &:focus {
      color: #ffffff;
      background-color: #2e3c3d;
      border-color: #2e3c3d;
    }
  }
}

.dots-1,
.dots-2 {
  position: absolute;
}
.dots-1 {
  top: 0;
  left: 0;
}
.dots-2 {
  right: 0;
  bottom: 0;
}
.particles-container {
  position: relative;
}
.easy-flow-container {
  width: 100vw;
  background-color: $bg-color-1;
}

.try-mplo-container {
  width: 100vw;
  background-color: #34bac1;
}

.footer-container {
  width: 100vw;
  background-color: #131414;
}

.easy-flow-content {
  display: flex;
  padding-block: 5rem;
  &-heading h1 {
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
  &-sub-text p {
    font-size: 1.35rem;
    font-weight: 300;
  }
  &-text {
    flex-basis: 30%;
    padding-right: 2.5rem;
    margin: auto;
  }
  &-button {
    margin-top: 1.5rem;
    button {
      line-height: 32px;
      color: #ffffff;
      background-color: #303f40;
      border-color: #303f40;
      padding: 0.2rem 2.5rem;
      border-radius: 100px;
      &:active,
      &:hover,
      &:focus {
        color: #ffffff;
        background-color: #2e3c3d;
        border-color: #2e3c3d;
      }
    }
  }
  &-pictures {
    flex-basis: 70%;
    margin-left: 2.5rem;
    position: relative;
    .time-log {
      position: relative;
      margin: 15% 7.5%;
      filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.1));
      border-radius: 5px;
      img {
        width: 100%;
      }
    }
    .circle-1,
    .circle-2,
    .user-icon1,
    .user-icon2 {
      position: absolute;
    }
    .circle-1,
    .user-icon1 {
      left: 0;
      bottom: 0;
    }
    .circle-2,
    .user-icon2 {
      right: 0;
      top: 0;
    }

    .user-icon1,
    .user-icon2 {
      padding: 4.4rem;
    }
  }
}

.try-mplo-content {
  padding-block: 5rem;
  color: #ffffff;

  &-text {
    line-height: 3.25rem;
    font-size: 2.5rem;
    margin-inline: auto;
    text-align: center;
    width: 50%;
    font-weight: 500;
  }
  &-button {
    text-align: center;
    margin-top: 5rem;
    button {
      line-height: 32px;
      color: #131414;
      background-color: #ffffff;
      border-color: #ffffff;
      padding-inline: 1.2rem;
      &:active,
      &:hover,
      &:focus {
        color: #131414;
        background-color: #fcfcfc;
        border-color: #fcfcfc;
      }
    }
    span {
      font-weight: 500;
    }
  }
  &-bottom-text {
    display: flex;
    justify-content: space-around;
    margin-top: 7.5rem;
    > div {
      display: flex;
      align-items: center;
      span {
        margin-left: 0.5rem;
      }
    }
  }
}

.footer-content {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding-block: 5rem;
  &-mplo-logo {
    > img {
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
  &-social-media {
    margin-top: 2.5rem;
    display: flex;
    div {
      margin-right: 1.5rem;
    }
  }
  &-company,
  &-support,
  &-subscribe {
    h1 {
      font-size: 1.8rem;
      font-weight: 400;
      margin-bottom: 1.1rem;
    }
    p {
      font-weight: 300;
    }
  }
  &-subscribe {
    flex-grow: 0.06;

    &-input {
      position: relative;
      input {
        color: #ffffff;
        background-color: #2a2c2c;
        border-radius: 5px;
        padding: 0.45rem 1rem;
        padding-right: 2rem;
        border: none;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
      img {
        position: absolute;
        right: 0.75rem;
        top: 27%;
        cursor: pointer;
      }
    }
  }
}
