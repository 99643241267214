.missed-logs-message {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-primary-user);
  p {
    margin-bottom: 0;
  }
  p.mb-0 {
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 0.4rem;
    span {
      font-style: italic;
      border-bottom: 2px solid var(--color-primary-user);
      transition: all 0.3s ease;
      cursor: pointer;
      letter-spacing: 0.3px;
      &:hover {
        background-color: var(--color-primary-user);
        color: white;
        padding-inline: 0.25rem;
        border-radius: 2px;
      }
    }
  }
}
.log-time-btn {
  border: 1px solid var(--color-primary-user);
  border-radius: 1rem;
  height: 1.5rem;
  padding-top: 0.15rem;
  @media only screen and (max-width: 1363.98px) {
    height: 1.5rem;
    padding-top: 2.5px;
  }
  color: #fff;
  background-color: var(--color-primary-user);
  font-weight: 600;
  width: 6.2rem;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: bottom;
  white-space: nowrap;
  &:hover {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  &:active {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  }
}
.start-timer-btn {
  background-color: var(--color-primary-user);
  border: 1px solid var(--color-primary-user);
  color: #fff;
  margin: 0 auto;
  font-size: 0.7rem;
  padding-inline: 0.6rem;
  padding-block: 0.4rem;
  &:hover {
    background-color: var(--color-primary-user);
    border: 1px solid var(--color-primary-user);
    color: #fff;
  }
}
.disabled-btn {
  color: #f1f1f1;
  pointer-events: none;
}

.projects-header {
  padding: 1.465rem 1.5rem;
}

.view-logs-btn {
  border: 1px solid var(--color-primary-user);
  border-radius: 1rem;
  height: 1.5rem;
  padding-top: 0.15rem;
  @media only screen and (max-width: 1363.98px) {
    height: 1.5rem;
    padding-top: 2.5px;
  }
  color: var(--color-primary-user);
  font-weight: 600;
  width: 6.2rem;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: bottom;
  white-space: nowrap;
  &:hover {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  &:active {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  }
}
.user-dashboard-card {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1));
  padding: 0.35rem 0.6rem;
  text-align: center;
  h3 {
    font-size: 0.75rem;
    color: #898989;
    font-weight: 100;
    margin: 0;
  }
  p {
    color: #2dcecc;
    font-size: 0.78rem;
    margin: 0;
    font-weight: 400 !important;
  }
}
.user-dashboard-card-loader {
  height: 0.875rem;
  margin-top: 0.32rem;
  -webkit-animation: progress;
  animation: progress;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
}
.align-item-center {
  align-items: center;
}
