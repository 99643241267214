:root {
  --color-primary-user: #7689de;
  --color-primary-user-light: #8495e1;
  --color-secondary-user: #a9dce3;
  --color-secondary-user-light: #c2e6eb;
  --color-warning: #f5365c;

  --color-primary-admin: #2dcecc;
  --color-secondary-admin: #2dce89;

  --color-logo-1: #36bfbf;
  --color-logo-2: #f2762e;

  --color-primary-user: #2dcecc;
  --color-primary-user-dark: #34bac1;

  --color-background: #0b1340;
  --color-background-light: #142377;
}
i {
  cursor: pointer;
}

/*VALIDATIONS STYLING*/

.invalid-feedback {
  margin-left: 0.5rem;
  font-size: 0.75rem !important;
}
.b-r-top-right {
  border-top-right-radius: 0.4rem !important;
}
.custom-invalid-feedback .input-group-alternative,
.custom-invalid-feedback .custom-select-toggle {
  box-shadow: 0px 0px 0px 1px #c2241c;
  border-radius: 0.4rem;
}
.custom-invalid-feedback-date .form-control {
  box-shadow: 0px 0px 0px 1px #c2241c !important;
}

.form-control {
  padding-left: 0.5rem !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.custom-invalid-feedback-text {
  margin-left: 0.25rem;
  font-size: 0.75rem;
  font-weight: 300;
  margin-bottom: 0.25rem;
  color: #c2241c;
}
.custom-invalid-modal-feedback {
  margin-left: 0.25rem !important;
  color: #c2241c;

  font-weight: 300;
}

.hidden-class {
  visibility: hidden;
}

.w-12 {
  width: 12% !important;
}

.w-5 {
  width: 5%;
}

/* LINK CLASSES */

.custom-link-class {
  color: #2dcecc;
  font-weight: 600;
  font-size: 0.83rem;
}
.custom-link-class:hover {
  color: #2dcecc;
  font-weight: 600;
}
.custom-link-class-active {
  color: #2dce89;
}
.custom-link-class > .fa-eye {
  font-size: 1rem;
}
.bg-gradient-custom {
  background: linear-gradient(87deg, #49a09d 0%, #085078 100%);
}
.bg-gradient-custom-light {
  background: linear-gradient(87deg, #f6f9fc 50%, #fafafa 100%) !important;
}
.bg-custom {
  background-color: var(--color-background);
}
.fill-danger {
  fill: var(--color-background);
}
.btn-custom {
  background-color: var(--color-background-light) !important;
  border-color: var(--color-background-light) !important;
}
.btn-custom:hover {
  background-color: var(--color-background-light) !important;
  border-color: var(--color-background-light) !important;
}
.custom-label {
  padding: 0.5rem;
  margin: 0;
  font-family: 'sanFranciso', sans-serif !important;
}
.filter-icon-wrapper {
  font-size: 1.5rem;
  color: #fb6340;
}
table td {
  white-space: initial !important;
}
.custom-flex-class {
  display: flex;
}
.add-log-button {
  padding: 0.75rem 0.65rem;
  font-size: 1rem;
}
.nav-link-text,
.user-select-none {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.no-data-div,
.no-data-card,
.no-activity-data {
  justify-content: center;
  width: 100%;
  text-align: center;
  font-style: italic;
  color: #787878;
  margin: 0.9375rem 0 1.75rem;
  font-size: 0.875rem;
}

.no-activity-data {
  margin-top: 1.75rem;
}
.no-data-card {
  margin-bottom: 0.75rem;
}

.text-disabled {
  color: #808080 !important;
}
.spinner-modal .modal-content {
  background: transparent !important;
  box-shadow: none !important;
  text-align: center;
  margin: auto;
}

.disabled-text {
  color: #aaa;
}
.disabled .page-link {
  background-color: #ccc !important;
}
.mr-6-5 {
  margin-right: 8.3rem;
}
.icons-wrapper-button {
  border: 0;
  background-color: inherit;
  color: #f5365c;
}
.icons-wrapper-button:disabled {
  color: #ccc;
  cursor: alias;
}
.icons-wrapper-button:disabled i {
  cursor: alias;
}
.pl-5-5 {
  padding-left: 2.25rem !important;
}
.custom-card-profile-image img {
  max-width: 100px;
  transition: all 0.15s ease;
}
.custom-card-profile-image img:hover {
  transform: translate(0%, -3%);
}

.associate-user-modal.modal-dialog {
  max-width: fit-content;
}
.client-edit-button-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-delete-btn {
  color: #f5365c;
}

.admin-theme-button {
  background-color: #2dcecc !important;
  border-color: #2dcecc !important;
}
.user-theme-button {
  background-color: var(--color-primary-user) !important;
  border-color: var(--color-primary-user) !important;
}
.custom-btn-size {
  font-size: 0.8rem;
  padding: 0.35rem 0.5rem;
}
.custom-btn-size2 {
  font-size: 0.8rem;
  padding: 0.5rem 0.75rem;
}
.btn-width {
  width: 5rem;
}

.admin-theme-color {
  color: #2dcecc !important;
}
.admin-theme-color:hover {
  color: #2dce89 !important;
}
.admin-theme-color-second {
  color: #2dce89 !important;
}

.user-theme-color {
  color: var(--color-primary-user) !important;
}
.user-theme-color:hover {
  color: var(--color-primary-user-dark) !important;
}

.user-theme-color-second {
  color: var(--color-primary-user-dark) !important;
}
.project-duration {
  font-size: medium;
  display: block;
  text-align: center;
  padding-bottom: 0.5rem;
}

.project-duration-fields {
  display: flex;
  justify-content: space-around !important;
}
.pink-row {
  background-color: rgba(255, 243, 244, 0.568);
}
.width-10 {
  width: 10%;
}
.width-20 {
  width: 20%;
}
.nav-link.active {
  background-color: #f6f9fca1;
}

.nav-link.active:before {
  border-left: 2px solid #2dcecc !important;
}
.user-active-sidebar .nav-link.active:before {
  border-left: 2px solid var(--color-primary-user) !important;
}

.clients-table th:first-child {
  width: 1%;
}
.clients-table th:nth-child(2) {
  width: 14%;
}
.clients-table th:nth-child(3),
.clients-table th:nth-child(5) {
  width: 12%;
}
.clients-table th:nth-child(4),
.clients-table th:nth-child(6) {
  width: 13%;
}
.clients-table th:nth-child(7) {
  width: 3%;
}
.clients-table td:nth-child(7) {
  text-align: center;
}

.list-button > i {
  margin-right: 0.25rem;
}

.project-table-class th:first-child {
  width: 0.1%;
}
.project-table-class th:nth-child(2) {
  width: 12%;
}
.project-table-class th:nth-child(3) {
  width: 13%;
}

.project-table-class th:nth-child(4) {
  width: 14%;
}
.project-table-class th:nth-child(5) {
  width: 0.01%;
}
.project-table-class th:nth-child(6),
.project-table-class th:nth-child(7),
.project-table-class th:nth-child(8) {
  width: 10%;
}
.project-table-class td:nth-child(8) {
  text-align: center;
}

.employee-table th:first-child,
.employee-table th:nth-child(6) {
  width: 2%;
}
.employee-table th:nth-child(2),
.employee-table th:nth-child(3) {
  width: 25%;
}
.employee-table th:nth-child(4),
.employee-table th:nth-child(5) {
  width: 18%;
}

.employee-table.archived {
  color: #525f7f9d;
}
.employee-table.archived tr th {
  color: #8898aa9d;
  background-color: #f6f9fc9d;
}

.integration-table th:first-child {
  width: 25%;
}
.integration-table th:nth-child(2),
.integration-table th:nth-child(3) {
  width: 10%;
}
.integration-table td:nth-child(3) {
  text-align: end;
}

.disabled-integration-button {
  pointer-events: none;
}

.integration-icon {
  display: flex;
  align-items: center;
}
.integration-icon img {
  margin-right: 0.5rem;
}

.project-details-table th:first-child {
  width: 8%;
}
.project-details-table th:nth-child(2) {
  width: 0.01%;
}
.project-details-table th:nth-child(3) {
  width: 5%;
}

.project-details-table th:last-child {
  width: 2%;
}
.project-details-table tr > td:last-child {
  padding-left: 3rem;
}

@media screen and (min-width: 1600px) {
  .project-details-table tr > td:last-child {
    padding-left: 5rem;
  }
}

.dashboard-employee-table th:first-child {
  width: 55%;
}
.dashboard-employee-table th:last-child {
  width: 45%;
}

.dashboard-project-table th:first-child,
.dashboard-project-table th:nth-child(2) {
  width: 10%;
}
.dashboard-project-table th:nth-child(3) {
  width: 15%;
}
.dashboard-project-table th:last-child {
  width: 0.01%;
}

.custom-breadcrumb-admin.no-button {
  line-height: 2;
}

.custom-text-button,
.custom-text-button:hover,
.custom-text-button:focus {
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;
  color: #2dcecc;
  padding: 0;
}

.custom-parent-toggler input:checked + .custom-child-toggler {
  border-color: #2dcecc !important;
}
.custom-parent-toggler input:checked + .custom-child-toggler:before {
  background: #2dcecc !important;
}
.custom-parent-toggler-role input:checked + .custom-child-toggler-role {
  border-color: #ffffff !important;
  background-color: #ff8f2e;
}
.custom-parent-toggler-role input:checked + .custom-child-toggler-role:before {
  background: #f0f0f0 !important;
}
.toggled-date,
.toggle-name {
  cursor: pointer;
  user-select: none;
}
.welcome-text {
  font-size: 2.7rem;
}
.form-label-text {
  color: #8898aa;
}

.no-user-img {
  height: 36px;
  width: 36px;
}
.lowercase-text {
  text-transform: lowercase;
}
.cursor-normal {
  cursor: auto;
}

.custom-styled-label {
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
}
.icon-width {
  width: 42px;
}

.input-not-select::placeholder {
  color: #8898aa;
}

.user-text {
  text-transform: lowercase;
}
.user-text::first-line {
  text-transform: capitalize;
}

.btn-custom-size {
  padding: 0.4rem 1rem;
}

.custom-heading {
  position: relative;
  top: -2rem;
  font-size: 2rem;
  font-family: 'Inter';
  color: #ffffff;
}
.custom-page-item-admin.active .page-link {
  background-color: #2dcecc;
  border-color: #2dcecc;
}
.custom-page-item-user.active .page-link {
  background-color: var(--color-primary-user);
  border-color: var(--color-primary-user);
}
.verify-token-spinner {
  width: 2.75rem;
  height: 2.75rem;
  margin: 0.35rem 0;
  border: 0.35rem solid var(--color-secondary-admin);
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.verify-token-container {
  margin-top: 0.3rem;
}
.transparent-modal .modal-content {
  background-color: transparent;
}
.verify-modal-content {
  text-align: center;
  padding: 1rem;
  font-size: 1.3rem;
  border-radius: 2px;
}

.failed-verify-msg {
  text-align: center;
}
.failed-verify-msg i {
  font-size: 3.25rem;
  margin: 0.35rem 0;
}
.failed-verify-msg div {
  text-align: center;
  padding: 1rem;
  font-size: 1.3rem;
}
.verify-modal-content .div {
  border-radius: 5px;
}

.custom-back-link {
  position: relative;
  top: -3rem;
  padding: 1rem;
  width: fit-content;
  color: #32325d;
}
.custom-back-link:hover {
  cursor: pointer;
  color: #fff;
  -webkit-transform: translateY(-0.1rem);
  transform: translateY(-0.1rem);
}
.custom-breadcrumb-admin {
  font-weight: 600;
  color: #32325d;
}
.custom-breadcrumb-admin span {
  color: #2dcecc;
}
.custom-breadcrumb-admin span:hover {
  color: #2dce89;
  cursor: pointer;
}
.custom-breadcrumb-user {
  font-weight: 600;
  color: #32325d;
}
.custom-breadcrumb-user span {
  color: var(--color-primary-user);
}
.custom-breadcrumb-user span:hover {
  color: var(--color-primary-user-dark);
  cursor: pointer;
}

.heading-text {
  font-size: 1.625rem;
  padding-bottom: 0.5rem;
}
.custom-disabled {
  cursor: pointer;
  pointer-events: none;
  background-color: #eee !important;
}

select {
  color: #9e9e9e !important;
}
option:not(:first-of-type) {
  color: #40413d !important;
}

input,
label {
  color: #40413d !important;
}

input:placeholder {
  color: #9e9e9e !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #9e9e9e !important;
}

::-ms-input-placeholder {
  color: #9e9e9e !important;
}

.main-text-color {
  color: #40413d !important;
}
.minus-margin-left {
  margin-left: -0.5rem;
}
.input-tag-w10 {
  width: 10rem !important;
}

.sidenav-child .nav-item .nav-link {
  font-size: 0.8125rem;
}

.user-project-table th:first-child {
  width: 20%;
}
.user-project-table th:nth-child(2) {
  width: 20%;
}
.user-project-table th:nth-child(3) {
  width: 30%;
}

.user-project-table th:nth-child(4) {
  width: 10%;
}
.user-project-table td:nth-child(4) {
  text-align: right;
}
.user-project-table th:nth-child(5) {
  width: 10%;
}

.password-strength-container {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.password-strength-container {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.bg-gradient-user {
  background: linear-gradient(90.91deg, #ff8f2e 5.46%, #34bac1 107%) !important;
}
.bg-gradient-admin {
  background: linear-gradient(
    268.76deg,
    #ff8f2e -9.67%,
    #34bac1 107.57%
  ) !important;
}

.delete-button {
  color: var(--color-warning);
}
.logs-edit-btn {
  color: var(--color-primary-user);
  font-size: 0.9rem;
}

.archive-toggle {
  color: var(--color-primary-admin);
  user-select: none;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.75rem 0 1.75rem;
}
.archive-toggle:hover {
  color: var(--color-secondary-admin);
}

.password-reset-success {
  text-align: left;
  margin-bottom: 2.5rem;
}
.dropdown-logout:active {
  background: var(--gray);
}
.disabled-percentage {
  color: gray;
}
.cursor-pointer {
  cursor: pointer;
}
.delete-div {
  padding: 0.5rem 0.75rem;
}

.user-action-bar {
  padding: 0.25rem 0;
}

.text-admin {
  color: var(--color-primary-admin) !important;
}
.text-user {
  color: var(--color-primary-user) !important;
}
.logs-spinner.no-header {
  margin-top: 1.5rem;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 1rem;
  height: 1rem;
}

.custom-control {
  padding-left: 5rem;
  min-height: 1.25rem;
}

.pl-pt-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.client-edit-button-child .save-btn {
  margin-right: 0;
}

.spinner-container {
  width: 65.5px;
  text-align: center;
}

.user.custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: var(--color-primary-user);
  background-color: var(--color-primary-user);
}

.user.custom-checkbox
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  border-color: var(--color-primary-user);
  background-color: var(--color-primary-user);
}

.project-list.user a {
  color: var(--color-primary-user);
}

.not-found-message {
  font-size: 0.875rem;
}
.spinner-lg {
  display: inline-block;
  width: 20rem;
  height: 20rem;
  vertical-align: text-bottom;
  border: 1.25em solid var(--color-primary-admin);
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.text-xlg {
  font-size: 5rem;
  color: #fff;
}
.redirect-header {
  background-color: #fff;
}
.redirect-header center {
  height: 100vh;
  justify-content: center;
}
.cursor-pointer {
  cursor: pointer;
}
.modal-overflow-y {
  height: '75vh';
  overflow-y: 'auto';
}
.custom-invalid-feedback-text-mt-1 {
  margin-left: 0.25rem;
  font-size: 0.75rem;
  font-weight: 300;

  margin-bottom: 0.25rem;
  color: #c2241c;
}
.btn-outline-admin {
  color: var(--color-primary-admin);
  border-color: var(--color-primary-admin);
  background-color: transparent;
  background-image: none;
  font-weight: 400;
  &:hover {
    color: #fff;
    background-color: var(--color-primary-admin);
  }
  &.disabled {
    cursor: not-allowed;
  }
}
.color-admin {
  color: var(--color-primary-admin);
}
