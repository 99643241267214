.log-card {
  font-size: 0.8125rem;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  &-header {
    > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    padding: 1rem 1.25rem;
    .styled-checkbox {
      padding-left: 32px;
      &.user-card {
        .checkbox-text {
          color: var(--color-primary-user);
          font-size: 1.1rem;
          font-weight: 500;
        }
        .checkmark {
          margin-top: 0.23rem;
          @media only screen and (max-width: 1363.98px) {
            margin-top: 0.25rem;
          }
        }
      }
    }
  }
  &-heading {
    font-size: 0.95rem;
    font-weight: 500;
    padding-bottom: 0.75rem;
    color: #2dcecc;
  }
  &-body {
    padding: 1rem 1.25rem;
  }
  &-footer {
    padding: 1rem 1.25rem;
  }
  &-edit-btn {
    color: var(--color-primary-user);
    font-size: 1rem;
  }
  &-day {
    margin-left: 2.3rem;
  }
  &-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-details {
    margin-top: 1rem;
    width: 100%;
  }

  &-sub-heading {
    font-weight: 500;
    color: #8898b2;
  }
}

.styled-checkbox {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkbox-text {
    color: #525f7f;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    margin-top: 0.15rem;
    @media only screen and (max-width: 1363.98px) {
      margin-top: 0.05rem;
    }
  }

  input:checked ~ .checkmark {
    background-color: var(--color-primary-user);
    border-color: var(--color-primary-user);
  }
  input:active ~ .checkmark {
    background-color: var(--color-primary-user);
  }
  input:disabled ~ .checkmark {
    background-color: rgba(45, 206, 204, 0.5);
    border-color: transparent;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 5px;
    top: 3px;
    width: 4px;
    height: 7px;
    @media only screen and (max-width: 1363.98px) {
      left: 4px;
      top: 2px;
    }
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
