.error-message {
  color: #c2241c;
}
.cursor-pointer {
  cursor: pointer;
}
.hidden-class {
  visibility: hidden;
}
.development-text {
  font-size: 1rem;
  font-weight: 400;
  color: #aaa;
  text-align: center;
}
