.log-form-group {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.log-form-group-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form-group-w-45 {
  width: 45%;
}
.form-group-w-100 {
  width: 100%;
}
div .form-group-w-100:first-child {
  margin-bottom: 0.5px;
}
.custom-invalid-label {
  color: #c2241c !important;
}
.edit-work-text-area {
  width: 100%;
}

.form-group-w-100 .work-details-text-area,
.form-group-w-100 .late-sitting-text-area {
  resize: none;
}
