.setup-company-container {
  min-width: 100vw;
  min-height: 100vh;
  background: #f4f5fa;
}
.setup-company-content {
  max-width: 1152px;
  margin-inline: auto;
  padding-top: 5.2rem;

  &-box {
    margin: 5rem;
    padding: 2.5rem 3rem;
    border-radius: 7px;
    border: 0.5px solid #303f40ab;
    background: #f8f9fe;
  }
  &-heading {
    width: 50%;
    margin-bottom: 1rem;
  }
  &-text {
    margin-bottom: 1rem;
    font-size: 0.85rem;
  }
  &-input {
    input {
      width: 68%;
      border-radius: 6px;
      margin-bottom: 1rem;
      padding: 0.35rem 0.75rem;
      -webkit-appearance: none;
      border: none;
      box-shadow: 0 0 0 1px rgb(224, 224, 224);
    }
    input:focus {
      outline: 0;
    }
  }
  &-button {
    margin-top: 1rem;
    button {
      &:hover {
        box-shadow: 0 0 0 1px rgb(224, 224, 224);
      }
    }
  }
}
