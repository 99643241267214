.workspace-container {
  background: #f4f5fa;
  min-height: 100vh;
}
.workspace-content {
  max-width: 1152px;
  margin-inline: auto;
}
.workspace-list {
  padding-inline: 5rem;
}
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-block: 2.5rem;
  position: relative;
  border: 0.5px solid #303f404f;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 300;
  background: #f8f9fe;
  .hd-invited,
  .workspace-details {
    padding: 1.5rem 2rem;
  }
  .hd-invited {
    span {
      font-weight: 500;
    }
  }
  .workspace-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0.5px solid rgb(224, 224, 224);
  }
}

.page-link {
  cursor: pointer;
  background: transparent !important;
  border: none !important;
  color: $base-color;
}

.invitations-heading {
  margin-bottom: 1rem;
  margin-left: 7px;
  font-weight: 500;
}
.invite-buttons {
  margin-top: unset;
}
.links-container {
  display: flex;
  justify-content: space-between;
}
.welcome-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-message-content {
  margin-top: 5.2rem;
}
.welcome-message {
  font-size: 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
  text-align: center;
  font-weight: 500;
}
.welcome-message-2 {
  color: #5a6268;
  font-size: 1.2rem;
}
.new-workspace-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 60%;
  border-radius: 10px;
  padding: 1.5rem 2rem;
  margin-bottom: 1.5rem;
  border: 0.5px solid #303f404f;
  background: #f8f9fe;
}
.new-workspace-message {
  font-size: 0.85rem;
}
.create-button {
  cursor: pointer;
  color: $base-color;
  background-color: #f8f9fe;
  border: 1px solid $base-color;
  border-radius: 100px;
  font-size: 0.85rem;
  padding: 0.15rem 1.75rem;
  transition: all 0.15s;
  &:hover {
    background-color: $base-color;
    color: #fff;
  }
}
.create-new-workspace-btn {
  background-color: $base-color;
  color: #fff;
  display: flex;
  padding: 0.15rem 1.25rem;
  white-space: nowrap;
  img {
    margin-right: 0.25rem;
    padding-bottom: 0.139rem;
  }
}
