$left-color: #242e4d;
$right-color: #897e79;
$green-dark: #35c3c1;
$green-light: #00d6b7;
$gray: #8f8f8f;
$gray-light: #f5f6f8;
$error-color: rgba(245, 57, 57, 0.966);
$base-color: #47bbc1;
$primary-color: #47bbc1;
$secondary-color: #ff8f2e;
$bg-color-1: #f8f9fe;
$bg-color-2: #f4f5fa;
